:root {
  /* Topbar */
  --height-topbar: 55px;

  /* Header */
  --height-header: 62px;
  --max-width-header: 1200px;
  --width-mobile-menu: 80vw;
  @media (min-width: 768px) {
    --height-header: 76px;
  }

  /* Content */
  --max-width-content: 100%;
  --max-width-section: 1200px;

  /* Footer */
  --max-width-footer: 100%;

  /* Fonts */
  --font-body: interstate, Helvetica, sans-serif;
  --font-heading: interstate, Helvetica, sans-serif;
  --font-subheading: gin, Helvetica, sans-serif;
  --font-monospace: monospace;

  /* Colors */
  --color-bg-header: #fff;
  --color-bg-topbar: #046a38;
  --color-bg-mobile-menu: #fff;
  --color-bg-footer: #046a38;
  --color-overlay: rgba(24, 56, 30, 0.88);
  --color-overlay-mobile-menu: rgba(0, 0, 0, 0.7); /* Optional */
  --color-text: #000;
  --color-background: #fff;
  --border-header: 1px solid var(--color-border-100); /* Optional */
  --color-primary: #18381e;
  --color-secondary: #046a38;
  --color-light-green: #56763c;
  --color-gray-100: #fafafa;
  --color-gray-200: #ebebeb;
  --color-gray-300: #c4c4c4;
  --color-gray-400: #979797;
  --color-gray-500: #6f6f6f;
  --color-border-100: #e2e2e2;
  --color-border-200: #a2a2a2;

  /* Toast */
  --toast-color: #fff;
  --toast-duration: 5.5s;
  --toast-border-radius: 3px;
  --toast-icon-fill: #fff;
  --toast-icon-height: 20px;

  /* Forms */
  --form-label-font-size: 14px;
  --form-label-color: var(--color-secondary);
  --form-input-height: 50px;
  --form-input-padding: 0 15px;
  --form-placeholder-color: #c4c4c4;
  --form-icon-fill: #888;
  --form-input-font-size: 16px;
  --form-input-border: 1px solid var(--color-gray-300);
  --form-input-border-radius: 2px;
  --form-select-border-active: 1px solid blue;
  --form-calendar-color-accent: var(--color-secondary);
  --form-calendar-color-active: var(--color-secondary);
  --form-calendar-font-size-day: 15px;
  --form-calendar-font-size-time: 15px;
  --form-calendar-color-muted: #e6efea;
  --form-calendar-width: 350px;
  --form-calendar-width-time: 300px;
  --form-calendar-height-time: 290px;
}
