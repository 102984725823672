.root {
  padding: 80px 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // .auth-divider {
  //   margin-top: 20px;
  //   padding-top: 20px;
  //   border-top: 1px solid var(--color-border-100);
  // }

  // .mkui-menu {
  //   list-style: none;
  //   padding: 0;
  //   margin: 0;
  // }
  // li {
  //   margin-bottom: 20px;
  // }
  // .menu-item a,
  // .menu-link {
  //   font-size: 23px;
  //   font-weight: 700;
  //   text-transform: uppercase;
  //   display: block;
  // }

  // .mkui-btn-mobile-menu {
  //   position: absolute;
  //   top: 10px;
  //   right: 15px;

  //   svg {
  //     height: 40px;
  //   }
  // }
}

.btn_logout {
  margin-bottom: 30px;
}
