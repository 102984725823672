// Labels & Containers
.mkui-instructions {
  margin: -5px 0 10px;
}

.mkui-field-label {
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.mkui-select-value {
  white-space: nowrap;
}

.mkui-field-container {
  margin-bottom: 10px;
}

.mkui-input,
.mkui-textarea,
.mkui-select-search,
.mkui-select {
  font-family: var(--font-body);
}

.mkui-textarea {
  padding: 15px !important;
  line-height: 1.6;
}

.mkui-form-divider {
  border-bottom: 1px solid var(--color-border-100);
  margin: 20px 0;
}

// Radio
.isGrad label {
  display: flex;
  align-items: center;
  input {
    margin: 0 10px 0 0;
  }
}

// Divider
.divider {
  border-bottom: 1px solid var(--color-border-300);
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.mkui-form-error {
  color: red;
  margin-top: 20px;
  font-size: 16px;
}

// Sort Dropdown

.select-label {
  font-size: 15px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.select-input {
  position: relative;
  select {
    font-family: var(--font-body);
    font-size: 13px;
    padding: 12px 30px 12px 10px;
    appearance: none;
    background-color: rgb(255, 255, 255);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0px 0px;
    background-size: 0.65em, 100%;
    border-color: var(--color-border-100);
    border-radius: 3px;
    margin-left: 10px;
  }
  .icon-caret {
    position: absolute;
    top: 46%;
    fill: var(--color-text);
    right: 10px;
    height: 5px;
  }

  @media (min-width: 768px) {
    select {
      font-size: 15px;
    }
  }
}

.mkui-date.unavailable.range-start,
.mkui-date.unavailable.range-end,
.mkui-date.unavailable.range-inner {
  opacity: 1;
  pointer-events: initial;
}

.mkui-date.range-inner {
  font-weight: 500;
}
