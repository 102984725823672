/* Topbar */
.mkui-topbar {
  color: #fff;
  padding: 3px 20px;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ul {
    list-style-type: none;
  }

  svg {
    fill: #fff;
  }

  span {
    font-family: var(--font-subheading);
    margin-right: 20px;
    letter-spacing: 1;
    opacity: 0.4;
  }
}

/* Header */

header {
  padding: 5px 15px;
  .logo-wrap {
    position: relative;
    height: 50px;
    width: 150px;
    img {
      object-fit: contain;
    }
  }
  .submenu {
    width: auto;
    line-height: 2;
    padding: 20px 10px !important;
    top: 85% !important;
    border: 1px solid var(--color-border-100);
    border-top: 2px solid var(--color-secondary);
  }

  @media screen and (min-width: 768px) {
    padding: 5px 30px;
    .logo-wrap {
      height: 65px;
      width: 250px;
    }
  }
}

.mkui-nav-menu {
  height: 100%;

  .menu-primary {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;

    .menu-item {
      height: 100%;
      align-items: center;
      width: max-content;
    }
  }

  a {
    display: block;
    padding: 0 17px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    transition: all ease 0.3s;
  }
}

main {
  line-height: 1.65;
}

/* Account Menu */

.account-menu {
  .mkui-dropdown-inner {
    position: relative;
  }

  .mkui-popover {
    top: 10px;
    right: 0;
  }
}

/* Mobile Menu */

.mkui-mobile-menu {
  .auth-divider {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--color-border-100);
  }

  .mkui-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 20px;
  }
  .menu-item a,
  .menu-link {
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .mkui-btn-mobile-menu {
    position: absolute;
    top: 10px;
    right: 15px;

    svg {
      height: 40px;
    }
  }
}

/* Footer */

// footer .container {
//   display: block;
// }

// .footer-content {
//   padding: 20px 20px 30px;
//   color: #fff;

//   .grid {
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 30px;

//     @media screen and (min-width: 768px) {
//       grid-template-columns: repeat(3, 1fr);
//     }
//   }

//   h4 {
//     text-transform: uppercase;
//     font-size: 19px;
//     margin-bottom: 35px;
//   }

//   a {
//     display: block;
//     color: #fff;
//     transition: all ease 0.3s;

//     &:hover,
//     &:focus {
//       opacity: 0.6;
//     }
//   }

//   .address {
//     margin-bottom: 15px;
//   }

//   .contact-link {
//     font-weight: 700;
//     margin-bottom: 5px;
//     display: inline-block;
//   }
// }

// .footer-menu {
//   padding-left: 0;
//   list-style-type: none;

//   a {
//     display: inline-block;
//     margin-bottom: 10px;
//     transition: all ease 0.3s;
//   }
// }

// .footer-announcement {
//   line-height: 1.7;
//   border: 1px solid #317e58;
//   padding: 20px 30px;
//   margin-bottom: 15px;
// }

// .copyright {
//   color: #fff;
//   width: 100%;
//   border-top: 1px solid #317e58;
//   text-align: center;
//   padding: 30px 20px;
//   font-size: 14px;

//   a {
//     color: #fff;
//     font-weight: 700;
//   }
// }
