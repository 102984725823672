// Toasts

.mkui-toast {
  font-size: 16px;
  &.success {
    background: #119152;
  }

  &.error {
    background: #d91919;
  }

  &.hint {
    background: #6840cc;
  }
}

// Buttons
.btn-naked {
  border: none;
  background: none;
  outline: none;
}

.btn-primary {
  font-size: 24px;
  font-family: var(--font-subheading);
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  border: none;
  outline: none;
  background: var(--color-secondary);
  padding: 10px 20px 13px;
  transition: all ease 0.3s;

  &:hover {
    color: #fff;
    background: var(--color-primary);
  }

  &.white {
    color: var(--color-primary);
    background: #fff;
  }

  &.small {
    font-size: 14px;
    padding: 8px 15px 10px;
  }
}

// Modal Forms

.modal-form {
  background: #fff;
  border-radius: 4px;
  max-width: 660px;
  margin: 0 auto;
  border-top: 10px solid var(--color-secondary);

  h2 {
    font-size: 32px;
    margin: 0;
    text-align: center;
  }

  .top {
    border-bottom: 1px solid var(--color-border-100);
  }

  .content-wrapper {
    padding: 30px;
  }

  p {
    text-align: center;
    line-height: 1.6;
  }
}

button[disabled] {
  background: var(--color-lightGray);
  cursor: initial;
  pointer-events: none;
}

.tab-button {
  outline: none;

  &.focus-visible {
    outline: 2px solid var(--color-primary);
  }
}

// Sections
.fade-section {
  opacity: 0;
  animation: fadeUp ease 0.3s 0.1s forwards;
}

// Tabs
.control-panel {
  .tab-navigation {
    border-bottom: 1px solid var(--color-border-100);
  }

  .tab-button {
    outline: none;
    background: none;
    border: 1px solid;
    border-color: transparent;
    padding: 15px 30px;
    margin-right: 20px;
    font-size: 19px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    color: var(--color-gray);
    text-transform: uppercase;
    font-weight: 700;
    transform: translateY(1px);
    transition: color ease 0.3s;

    &.active {
      background: var(--color-background);
      border-color: var(--color-border);
      border-bottom: 0;

      &:hover,
      &:focus {
        color: var(--color-secondary);
      }
    }

    &:hover,
    &:focus {
      color: var(--color-secondary);
    }
  }
}

// No items in list
.no-items {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: var(--color-gray);
  border: 1px solid var(--color-border-100);
  background: #fafafa;
}

// Host / Externship UI

.profile-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 40px 20px 80px;
  min-height: 80vh;

  @media (min-width: 768px) {
    padding: 80px 20px 150px;
    grid-template-columns: 400px 1fr;
    gap: 60px;
  }

  h1 {
    font-size: 46px;
    margin: 0px 0px 30px;
    line-height: 1.2;
  }
}

// Testimonial slider

.testimonial-slider {
  .mkui-carousel-arrow.left {
    border-right: 1px solid var(--color-border-100);
    bottom: 2px;
  }
  .mkui-carousel-arrow.right {
    border-left: 1px solid var(--color-border-100);
    bottom: 2px;
  }
  @media (max-width: 768px) {
    .mkui-carousel-arrow.left {
      top: unset;
      height: 70px;
      left: -1px;
      padding: 0 40px;
      border: 1px solid var(--color-border-100);

      svg {
        transform: scaleX(-1);
      }
    }

    .mkui-carousel-arrow.right {
      top: unset;
      height: 70px;
      bottom: 1px;
      right: -1px;
      padding: 0 40px;
      border: 1px solid var(--color-border-100);

      svg {
        transform: none;
      }
    }
  }
}
