.root {
  @media (max-width: 768px) {
    display: none;
  }
}

.container {
  background: var(--color-bg-header);
  width: 350px;
  box-shadow: 3px 7px 12px rgba(0, 0, 0, 0.02);
  border: 1px solid var(--color-border-100);
  font-size: 16px;
}

.header {
  padding: 20px;
}

.account-name {
  font-weight: 700;
  font-size: 17px;
}

.icon {
  fill: var(--color-gray-300);
}

.type {
  display: inline-flex;
  font-size: 14px;
  border-radius: 3px;
  letter-spacing: 2px;
  padding: 2px 3px 4px 5px;
  margin-top: 5px;
  font-family: var(--font-subheading);
  background: var(--color-gray-200);
  color: var(--color-secondary);
}

.image {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

// Menu List

.menu {
  padding: 0;
  margin: 0;
  list-style: none;

  a,
  button {
    color: var(--color-secondary);
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 20px;
    border: none;
    background: transparent;
    border-top: 1px solid var(--color-border-100);
    transition: all ease 0.3s;

    span {
      transition: all ease 0.3s;
    }

    &:hover {
      background: #fafafa;

      span {
        transform: translateX(5px);
      }
    }
  }
}

// Buttons

.btn_logout {
  svg {
    fill: var(--color-secondary);
    height: 20px;
    margin-right: 10px;
  }
}

.btn_account {
  border: 1px solid;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-gray-500);
  border-color: var(--color-border-100);
  background: var(--color-bg-header);
  border-radius: 2px;
  margin-left: 40px;
  padding: 12px 20px;
  transition: all ease 0.3s;

  &:hover {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
  }
}
