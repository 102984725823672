a {
  color: var(--color-secondary);
  text-decoration: none;
}

button {
  font-family: var(--font-heading);
  cursor: pointer;
}

main {
  font-size: 18px;
  line-height: 1.7;
}

.flex-wrap {
  flex-wrap: wrap;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100%;
}
