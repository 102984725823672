/* Transitions */

.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0px);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active,
.fade-exit-active {
  transition: all ease 0.3s;
}

/* Animations */

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
