.root {
  padding: 20px 20px 30px;
  color: #fff;

  h4 {
    text-transform: uppercase;
    font-size: 19px;
    margin-bottom: 35px;
  }

  a {
    display: block;
    color: #fff;
    transition: all ease 0.3s;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.address {
  margin-bottom: 15px;
}

.contact {
  font-weight: 700;
  margin-bottom: 5px;
  display: inline-block;
}

.menu {
  padding-left: 0;
  list-style-type: none;

  a {
    display: inline-block;
    margin-bottom: 10px;
    transition: all ease 0.3s;
  }
}

.announcement {
  line-height: 1.7;
  border: 1px solid #317e58;
  padding: 20px 30px;
  margin-bottom: 15px;
}

.copyright {
  color: #fff;
  width: 100%;
  border-top: 1px solid #317e58;
  text-align: center;
  padding: 30px 20px;
  font-size: 14px;

  a {
    color: #fff;
    font-weight: 700;
  }
}
